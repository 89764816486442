@import '../../../helpers/componentHeader';
@import '../../../components/buttons';
// 89347
// 125916
.experience-commerce-video-layout {
    .product-video-thumbnail {
        position: relative;
        @include media-breakpoint-up(md) {
            height: 320px;
        }
        @include media-breakpoint-up(lg) {
            height: 400px;
        }
        .product-thumbnail {
            height:100%;
            width: 100%;
            object-fit: cover;
        }
        .video-mask {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
        .btn-video-popup {
            background: url("../../../../images/play-video-xl.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 105px;
            height: 105px;
            border: 0px;
            position: absolute;
            margin: auto;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            &:hover,
            &:focus,
            &:active {
                opacity: .86;
            }
            @include media-breakpoint-down(sm) {
                width: 72px;
                height: 72px;
            }
            @include media-breakpoint-down(sm) {
                background: url("../../../../images/play-video.svg");
                width: 36px;
                height: 36px;
            }
        }
    }
}

.modalVideoLayout {
    .modal-body {
        padding-bottom: 40px!important;
    }
}